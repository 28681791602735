import { log } from './gtm.utils';
var _layer = 'dataLayer';
var _id = '';
function gtmClient(ctx, initialized) {
  return {
    init() {
      var id = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _id;
      if (initialized[id] || !window._gtm_inject) {
        return;
      }
      window._gtm_inject(id);
      initialized[id] = true;
      log('init', id);
    },
    push(obj) {
      if (!window[_layer]) {
        window[_layer] = [];
      }
      window[_layer].push(obj);
      log('push', obj);
    }
  };
}
function gtmServer(ctx, initialized) {
  var events = [];
  var inits = [];
  ctx.beforeNuxtRender(() => {
    if (!inits.length && !events.length) {
      return;
    }
    var gtmScript = ctx.app.head.script.find(s => s.hid == 'gtm-script');
    gtmScript.innerHTML = "window['".concat(_layer, "']=").concat(JSON.stringify(events), ";").concat(gtmScript.innerHTML);
    if (inits.length) {
      gtmScript.innerHTML += ";".concat(JSON.stringify(inits), ".forEach(function(i){window._gtm_inject(i)})");
    }
  });
  return {
    init() {
      var id = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _id;
      if (initialized[id]) {
        return;
      }
      inits.push(id);
      initialized[id] = true;
      log('init', id);
    },
    push(obj) {
      events.push(obj);
      log('push', JSON.stringify(obj));
    }
  };
}
function startPageTracking(ctx) {
  ctx.app.router.afterEach(to => {
    setTimeout(() => {
      ctx.$gtm.push(to.gtm || {
        routeName: to.name,
        pageType: 'PageView',
        pageUrl: '' + to.fullPath,
        pageTitle: typeof document !== 'undefined' && document.title || '',
        event: 'nuxtRoute'
      });
    }, 250);
  });
}
export default function (ctx, inject) {
  var runtimeConfig = ctx.$config && ctx.$config.public.gtm || {};
  var autoInit = false;
  var id = '';
  var runtimeId = runtimeConfig.id;
  var initialized = autoInit && id ? {
    [id]: true
  } : {};
  var $gtm = process.client ? gtmClient(ctx, initialized) : gtmServer(ctx, initialized);
  if (autoInit && runtimeId && runtimeId !== id) {
    $gtm.init(runtimeId);
  }
  ctx.$gtm = $gtm;
  inject('gtm', ctx.$gtm);
  if (process.client) {
    startPageTracking(ctx);
  }
}